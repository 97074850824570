/* Header */
.header {
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 10;
  &_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 48px;
    padding-bottom: 24px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: .1em; }
  &_logo {
    a {
      display: inline-block;
      margin-top: -35px; }
    img {
      max-width: 120px;
      max-height: 88px;
      object-fit: contain;
      object-position: center center; } }
  &_menu {
    &-wrap {
      display: flex;
      align-items: center;
      margin-left: -10px;
      margin-right: -10px; }
    &-item {
      padding: 0 10px;
      position: relative;
      transition: all .3s ease-in-out;
      &.has-sub {
        &:hover {
          .submenu {
            top: calc(100% + 10px);
            opacity: .8;
            visibility: visible; } }
        .submenu {
          transition: all .3s ease-in-out;
          opacity: 0;
          visibility: hidden;
          position: absolute;
          top: calc(100% - 10px);
          left: 0;
          padding: 10px;
          background: #FFF;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          z-index: 3;
          &_item {
            &:not(:last-child) {
              margin-bottom: 12px; } } } }
      &.contact-mobile {
        display: none; } } }
  &_contact {
    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        .svg {
          transition: all .3s ease;
          path {
            transition: all .3s ease; } } }
      &:hover {
        .icon .svg path {
          stroke: $primary; } } } } }

@include responsive(1100px) {
  .header {
    &_burger {
      display: block;
      width: 30px;
      height: 16px;
      position: relative;
      margin: 0;
      cursor: pointer;
      transition: all .25s ease-in-out;
      position: relative;
      z-index: 3;
      span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: $primary;
        position: absolute;
        left: 0;
        @include transform(rotate(0deg));
        @include transition(opacity .5s .5s,top .5s .4s,transform .5s);
        &:nth-child(1) {
          top: 0; }
        &:nth-child(2) {
          top: 7px; }
        &:nth-child(3) {
          top: 14px; } } }
    &_logo {
      position: relative;
      z-index: 3;
      a {
        margin-top: 0; }
      img {
        height: 40px; } }
    &_wrap {
      padding-top: 20px; }
    &_menu {
      &-wrap {
        display: block;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: -100%;
        padding: 20px;
        padding-top: 100px;
        background: #fff;
        z-index: 2; }
      &-item {
        transform: translateX(-50%);
        &:not(:last-child) {
          margin-bottom: 16px; }
        &.has-sub {
          &:hover {
            .submenu {
              max-height: max-content;
              padding: 10px;
              margin-top: 10px;
              box-shadow: none;
              background: transparent; } }
          .submenu {
            position: relative;
            padding: 0;
            max-height: 0; } } } }

    &_contact {
      display: none; } }

  body.main-menu-open {
    .header {
      &_burger {
        span {
          @include transition(opacity .5s,top .5s,transform .5s .4s);
          &:nth-child(1) {
            @include transform(rotate(45deg));
            top: 7px; }
          &:nth-child(2) {
            opacity: 0; }
          &:nth-child(3) {
            @include transform(rotate(-45deg));
            top: 7px; } } }
      &_menu {
        &-wrap {
          left: 0; }
        &-item {
          transform: translateX(0);
          &:nth-child(1) {
            transition-delay: .1s; }
          &:nth-child(2) {
            transition-delay: .2s; }
          &:nth-child(3) {
            transition-delay: .3s; }
          &:nth-child(4) {
            transition-delay: .4s; }
          &:nth-child(5) {
            transition-delay: .5s; }
          &:nth-child(6) {
            transition-delay: .6s; }
          &:nth-child(7) {
            transition-delay: .7s; }
          &:nth-child(8) {
            transition-delay: .8s; }
          &:nth-child(9) {
            transition-delay: .9s; }
          &:nth-child(10) {
            transition-delay: 1s; }
          &:nth-child(11) {
            transition-delay: 1.1s; }

          &.contact-mobile {
            display: flex;
            text-align: center;
            width: 100%;
            justify-content: center;
            margin-top: 60px;
            .header_contact {
              display: block; } } } } } } }
