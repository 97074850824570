/* GENERAL */
* {}
body {
  max-width: 100vw;
  overflow-x: hidden !important; }
main {
  min-height: 100vh;
  padding-top: 130px; }

section {
  h2 {
    font-size: 30px;
    line-height: 36px;
    color: $primary;
    margin-bottom: 60px;
    @include font-600(); } }


.masthead_small {
  position: relative;
  background: url(../images/masthead-small.jpg);
  background-size: contain;
  object-fit: cover;
  object-position: center;
  background-repeat: no-repeat;
  max-width: 90%;
  margin-bottom: 80px;
  &-img {
    width: 100%;
    max-width: 90%;
    height: auto;
    object-fit: cover;
    object-position: center; }
  &-text {
    // position: absolute
    // top: 0
    // left: 0
    padding: 100px 0 70px;
    width: 100%;
    color: #fff;
    .breadcrumbs {
      margin-bottom: 16px;
      ul {
        li {
          display: inline-block;
          vertical-align: middle;

          a {
            color: #fff; }
          & + li {
            padding-left: 3px;
            &:before {
              content: "/"; }
            a,
            span {
              padding-left: 3px; } } } } }
    h1 {
      color: #fff; } } }

.generalbox {
  padding: 35px;
  border-radius: 30px;
  background: linear-gradient(180deg, #0C6696 0%, #004367 100%);
  color: #FFF; }

.box-img {
  max-width: 100%;
  margin: 0 auto 30px;
  img {
    display: block;
    margin: 0 auto; } }

.btn-wrap {
  text-align: center;
  margin-top: 50px; }

.btn {
  padding: 8px 23px;
  border-radius: 25px;
  display: inline-block;
  &_primary {
    text-align: center;
    background: $primary !important;
    color: #fff;
    &:hover {
      color: #fff; } }
  &.w-arrow {
    .icon {
      display: inline-block;
      vertical-align: middle;
      width: 30px;
      height: 30px;
      img {
        width: 100%;
        height: 100%; } } } }

a.readmore {
  text-transform: uppercase;
  text-decoration: underline;
  color: $text; }

.pagination-box {
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
  .pagination {
    .page-item {
      &.active {
        .page-link {
          background: #0C6696;
          border-color: #0C6696;
          color: #fff; } } }
    .page-link {
      color: $text; } } }

.news {
  &_box {
    &-img {
      margin-bottom: 20px; }
    &-text {
      .date {
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 16px; }
      .title {
        h3 {
          font-size: 15px;
          line-height: 1.5em; }
        a {
          color: $secondary; } }
      p {
        color: #000; } } } }
