/* Typography */
h1, h2, h3, h4, h5, h6 {
  color: $primary;
  margin-top: 0;
  margin-bottom: 1rem;
  @include font-400();
  font-weight: 700; }

h1 {
  font-size: 35px;
  line-height: 45px; }

h2 {
  font-size: 24px;
  line-height: 29px;
  font-weight: 500; }

h3 {
  font-size: 20px;
  line-height: 30px; }

h4 {
  font-size: 16px;
  line-height: 19px; }

.small {
  font-size: 14px;
  line-height: 20px; }

a {
  transition: all .3s ease;
  color: $link;
  text-decoration: none;
  &:hover {
    color: $primary;
    text-decoration: none; } }

p {
  font-size: 16px;
  line-height: 1.5em;
  margin-top: 0;
  margin-bottom: 20px;
  a:hover {
    text-decoration: underline; }
  &:last-child {
    margin-bottom: 0; } }

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none; }

article {
  p {
    word-break: keep-all;
    /* Non standard for webkit */
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    &:last-child {
      margin-bottom: 0; } }

  a:not(.btn) {
    &:hover {
      text-decoration: underline; } }

  blockquote {
    font-style: italic; }

  li {
    ul, ol {
      margin: 0 24px;
      padding-top: 10px; } }

  ul, ol {
    margin: 0 0 32px;
    padding-left: 24px;
    &:last-child {
      margin: 0; }
    li {
      margin-bottom: 24px;
      &:last-child {
        margin: 0; } } }
  ul {
    list-style-type: disc; }
  ol {
    list-style-type: decimal;
    ol {
      list-style: lower-alpha;
      ol {
        list-style: lower-roman;
        ol {
          list-style: lower-alpha; } } } } }

/* BASE */
*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  // cursor:none !important
  &::-webkit-scrollbar {
    width: 15px;
    height: 15px;
    background-color: #F2F2F2; }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background-color: #F2F2F2; }
  &::-webkit-scrollbar-thumb {
    background-color: #BABABA; } }

img {
  max-width: 100%; }

hr {
  border: 0;
  border-top: 1px solid $border; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

html, body {
  @include font-400();
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  background-color: #FFFFFF;
  color: $text;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased; }
