.pengadaan {
  padding-bottom: 120px;
  &_list {
    padding-bottom: 80px;
    .nav-tabs {
      border-bottom: 0;
      margin-left: -12px;
      margin-right: -12px;
      width: 100%;
      justify-content: center;
      .nav-item {
        padding-left: 12px;
        padding-right: 12px;
        .nav-link {
          padding: 15px 30px;
          min-width: 380px;
          border-radius: 20px;
          background: $secondary;
          color: #FFF;
          border: 0;
          text-align: center;
          &.active {
            background: #F7F7F7;
            color: $secondary; } } }
      .filter {
        display: inline-block;
        vertical-align: middle;
        line-height: 50px; } }
    .tab-content {
      padding-top: 80px; }
    &-table {
      thead {
        th {
          background-color: $primary !important;
          color: #FFF !important;
          border-top: 0;
          border-bottom: 0;
          font-weight: 400;
          text-align: center;
          padding: 20px 30px;
          vertical-align: middle;
          &:first-child {
            border-radius: 25px 0 0 0; }
          &:last-child {
            border-radius: 0 25px 0 0; } } }
      tbody {
        td {
          padding: 24px 16px;
          border-top: 0;
          border-bottom: 0;
          text-align: center;
          color: $text; } } } } }

@include responsive(1100px) {
  .pengadaan {} }

