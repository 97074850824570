@import 'cores/mixin';
@import 'cores/vars';


/* ------------------------------------------------------------------------------------------------ */
/* Core style */
/* ------------------------------------------------------------------------------------------------ */

@import 'cores/fonts';
@import 'cores/base';


/* ------------------------------------------------------------------------------------------------ */
/* Component style */
/* ------------------------------------------------------------------------------------------------ */

@import 'components/animation';
@import 'components/general';
@import 'components/header';
@import 'components/footer';
@import 'components/content';

/* Page style */
@import 'pages/home';
@import 'pages/profil';
@import 'pages/pengadaan';
@import 'pages/karir';
@import 'pages/unit';
@import 'pages/cgc';
@import 'pages/sosial';
