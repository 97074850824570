/* Footer */
.footer {
  &_cta {
    position: relative;
    &:before {
      content: "";
      background: #E0E0E0;
      position: absolute;
      top: -27px;
      left: 0;
      width: 100vw;
      height: 115px;
      border-radius: 25px 0 0 0; }
    &-img {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      width: auto;
      max-width: 250px;
      margin-bottom: -27px; }
    &-form {
      .form-group {
        position: relative;
        width: 100%;
        max-width: 390px;
        margin-bottom: 0; }
      .form-control {
        background-color: #fff;
        border-radius: 20px;
        border: 0;
        padding: 16px 24px;
        min-height: 60px; }
      .send-cta {
        background: transparent;
        border: 0;
        padding: 0;
        position: absolute;
        top: 16px;
        right: 24px; } } }
  &_bottom {
    padding-top: 74px;
    padding-bottom: 95px; }
  &_logo {
    margin-bottom: 24px;
    img {
      max-height: 70px;
      max-width: 98px;
      object-fit: contain;
      object-position: center; } }
  &_info {
    color: #828282;
    font-size: 15px;
    line-height: 1.5em; }
  &_address {
    li {
      position: relative;
      display: flex;
      &:not(:last-child) {
        margin-bottom: 14px; }
      .icon {
        width: 20px;
        height: 20px;
        margin-right: 24px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center; } }
      address,
      a {
        max-width: calc(100% - 44px); }
      address {
        margin-bottom: 0; }
      a {
        color: #828282; } } }
  &_menu {
    padding-top: 32px;
    font-size: 12px;
    line-height: 1.5em;
    &-head {
      font-size: 12px;
      line-height: 1.5em;
      font-weight: 400;
      color: $secondary;
      margin-bottom: 32px; }
    ul {
      li {
        &:not(:last-child) {
          margin-bottom: 20px; } } } } }


@include responsive(1100px);

@include maxtablet {
  .footer {
    &_cta {
      &:before {
        top: 0;
        width: 105.6%; }
      &-img {
        margin: 0;
        margin-left: 50px;
        margin-bottom: 0;
        max-width: 180px; }
      &-form {
        padding: 27px 20px;
        .form-group {
          margin: 0 auto; } } }
    &_bottom {
      padding: 0 20px;
      padding-top: 40px;
      padding-bottom: 40px;
      // & > .row
      //   & > .col-lg-3
      //     order: 2
      //   & > .col-lg-9
 }      //     order: 1
    &_info {
      margin-bottom: 40px; }
    &_menu {
      padding-top: 0;
      & > .row {
        display: block; }
      &-head {
        margin-bottom: 20px; }
      ul {
        li {
          margin-bottom: 10px; } } } } }




