.tatakelola {
  padding-bottom: 140px;
  section:not(.masthead_small) {
    padding-bottom: 80px; }
  .box-img {
    width: 100%;
    margin: 0 auto 60px; }
  article {
    text-align: justify;
    h4 {
      color: $text; } }
  .generalbox {
    padding: 30px 23px;
    p {
      font-size: 12px;
      text-align: justify; } } }
