/* Fonts */

@include fontGenerator("Lato",(400),(lato-regular));
@include fontGenerator("Lato Medium",(400),(lato-bold));
@include fontGenerator("Lato Bold",(400),(lato-black));

@mixin font-400 {
  font-family: "Lato", sans-serif; }

@mixin font-600 {
  font-family: "Lato Medium", sans-serif; }

@mixin font-700 {
  font-family: "Lato Bold", sans-serif; }

