.unit {
  padding-bottom: 120px;
  section {
    margin-bottom: 80px; }
  &_section {
    .unit-detail {
      max-width: 400px; }
    .detail-item {
      &:not(:last-child) {
        margin-bottom: 30px; }
      h3 {
        color: #828282;
        margin-bottom: 12px;
        font-weight: 600; }
      .dummy-box {
        width: 100%;
        height: 150px;
        border: 1px solid $border; }
      .total {
        font-size: 32px;
        line-height: 38px;
        font-weight: 600; } } }

  &_more {
    .box-img {
      margin-bottom: 20px; }
    .box-name {
      h3 {
        font-size: 20px;
        line-height: 1.5em;
        a {
          color: #0C6696; } } } } }
