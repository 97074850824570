.profil {
  padding-bottom: 120px;
  section {
    padding-bottom: 80px; }
  &_visimisi {
    h2 {
      margin-bottom: 24px; }
    .generalbox {
      position: relative;
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: -92%;
        background: linear-gradient(180deg, #0C6696 0%, #004367 100%); } } }
  &_location {
    .box-map {
      width: 100vw;
      position: relative;
      padding-top: 562px;
      border-radius: 25px 0 0 25px;
      overflow: hidden;
      margin-bottom: 100px;
      .mapouter {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        .gmap_canvas {
          width: 100%;
          height: 100%;
          iframe {
            width: 100%;
            height: 100%; } } } }
    &-adress {
      width: 100%;
      max-width: 690px;
      margin: 0 auto;
      border: 3px solid $secondary;
      border-radius: 25px;
      padding: 30px 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon {
        width: 85px;
        height: 85px;
        flex: 0 0 85px;
        margin-right: 54px;
        img {
          width: 100%;
          height: 100%; } } } }

  &_plan {
    h3 {
      margin-bottom: 12px; }
    &-box {
      background: $secondary;
      border-radius: 25px;
      padding: 20px 25px;
      .inner-box {
        background: #fff;
        border-radius: 25px;
        padding: 60px 0; }
      .item {
        &:not(:last-child) {
          margin-bottom: 30px; } } } }

  &_saham {
    .box-img {
      max-width: 540px;
      margin: 0 auto; } }

  &_komisaris {
    &-wrap {
      &:not(:last-child) {
        margin-bottom: 40px; }
      h2 {
        margin-bottom: 35px; } }
    .box-komisaris {
      .box-img {
        width: 100%;
        padding-top: 125%;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%; } }
      .box-text {
        padding: 10px;
        border-radius: 10px;
        background: $secondary;
        color: #FFF;
        width: max-content;
        // max-width: 90%
        margin-left: 10px;
        margin-top: -30px;
        position: relative;
        .name {
          font-size: 18px;
          line-height: 1.5em; } } } }

  &_biography {
    .box-photo {
      position: relative;
      .box-text {
        padding: 10px 33px;
        background: $secondary;
        border-radius: 30px 0 30px 0;
        color: #FFF;
        width: max-content;
        position: absolute;
        right: 12px;
        bottom: 0;
        .name {
          font-size: 18px;
          line-height: 30px;
          margin-bottom: 8px; } } }
    article {
      text-align: justify; } } }




