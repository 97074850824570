.karir {
  padding-bottom: 120px;
  &_cari {
    .search {
      margin-bottom: 20px;
      .form-group {
        position: relative;
        max-width: 777px;
        margin: 0 auto;
        &:after {
          content: "";
          width: 26px;
          height: 26px;
          background: url(../images/ic-loop.png);
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          top: 13px;
          right: 30px; } }
      .form-control {
        width: 100%;
        background: #F7F7F7;
        border-radius: 20px;
        border: 0;
        height: auto;
        padding: 15px 50px; } }

    .filter {
      position: absolute;
      top: 8px;
      right: 20px; } }
  &_type {
    padding-bottom: 80px;
    .box-career {
      padding: 30px 24px;
      border-radius: 30px;
      margin-bottom: 20px;
      background: linear-gradient(180deg, #0C6696 0%, #004367 100%);
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 160px;
      .icon {
        width: 40px;
        height: 40px; }
      .text {
        margin-top: auto; } } }

  &_recent {
    padding-bottom: 80px;
    .card-header {
      button {
        color: $text; } }
    .card-body {
      ul {
        margin-bottom: 20px;
        li {
          display: inline-block;
          vertical-align: middle;
          &:not(:last-child) {
            margin-right: 20px; }
          .icon {
            display: inline-block;
            vertical-align: middle;
            width: 40px;
            height: 40px; }
          .text {
            display: inline-block;
            vertical-align: middle; } } } } } }
