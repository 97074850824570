.home {
  padding-top: 30px;
  padding-bottom: 120px;
  &_masthead {
    max-width: 90%;
    border-radius: 0 30px 0 0;
    overflow: hidden;
    position: relative;
    margin-bottom: 80px;
    &-navigation {
      position: absolute;
      right: 130px;
      bottom: 30px;
      z-index: 5;
      .slider-index {
        padding: 5px 50px;
        background: $primary;
        color: #fff;
        border-radius: 15px; } } }
  .slider {
    &-home {
      .owl-nav {
        position: absolute;
        bottom: 13px;
        right: 83px;
        z-index: 4;
        .owl-prev,
        .owl-next {
          background-color: $primary;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          span {
            width: 60px;
            height: 60px;
            display: inline-block;
            background-image: url(../images/ic-arrow-right-long.png); } }
        .owl-prev {
          display: none;
          transform: rotate(-180deg); } } }
    &_item {
      position: relative;
      &-img {}
      &-text {
        padding: 34px 100px;
        border-radius: 0 100px 0 0;
        background: $primary;
        color: #fff;
        max-width: 480px;
        position: absolute;
        left: 0;
        bottom: 0; } } }
  &-welcomemsg {
    padding-bottom: 140px;
    background-image: url(../images/home-aksen.png);
    background-repeat: no-repeat;
    background-position: 113% 100%;
    article {
      text-align: justify;
      h2 {
        margin-bottom: 40px; }
      .readmore {
        text-transform: uppercase;
        text-decoration: underline;
        color: $text; } } }
  &_chart {
    padding-bottom: 80px;
    &-box {
      padding: 30px 60px;
      border-radius: 30px;
      background: linear-gradient(180deg, #0C6696 0%, #004367 100%);
      color: #fff;
      position: relative;
      &.month {
        &:before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: -92%;
          background: linear-gradient(180deg, #0C6696 0%, #004367 100%); } }
      &.year {
        &:before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: -92%;
          background: linear-gradient(180deg, #0C6696 0%, #004367 100%); }
        h2 {
          text-align: right; } }
      h2 {
        margin-bottom: 50px; }
      .kwh {
        font-size: 50px;
        line-height: 60px;
        margin-bottom: 50px; }
      ul {
        li {
          &:not(:last-child) {
            margin-bottom: 20px; } } }
      .chart_box {
        canvas {
          height: 222px !important; } } } }
  &_career {
    padding-bottom: 80px;
    h2 {
      font-size: 30px;
      line-height: 36px;
      @include font-600();
      margin-bottom: 76px;
      text-align: center; }
    .slider {
      margin-bottom: 60px;
      &-item {
        padding: 30px 24px;
        border-radius: 30px;
        background: linear-gradient(180deg, #0C6696 0%, #004367 100%);
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 160px;
        .icon {
          width: 40px;
          height: 40px; }
        .text {
          margin-top: auto; } }
      .owl-nav {
        position: absolute;
        top: calc(50% - 25px);
        left: 0;
        width: 100%;
        .owl-prev,
        .owl-next {
          position: absolute;
          width: 50px;
          height: 50px;
          background-image: url(../images/ic-caretright.png);
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: center; }
        .owl-next {
          right: -70px; }
        .owl-prev {
          left: -70px;
          transform: rotate(-180deg); } } }
    &-btn {
      text-align: center;
      .btn_primary {
        margin: 0 auto; } } }

  &_gallery {
    padding-bottom: 80px;
    .box-img {
      padding: 10px;
      background: #fff;
      border-radius: 25px;
      box-shadow: -11px 15px 34px rgba(0, 0, 0, 0.15);
      border: 2px solid #E0E0E0; }
    .slider {
      min-height: 490px;
      &:before {
        content: "";
        background: $primary;
        width: 100%;
        height: 160px;
        position: absolute;
        top: calc(50% - 135px);
        left: 0; }
      .owl-item.center {
        margin-top: -50px;
        .box-img {
          max-width: 580px; } }
      .owl-stage-outer {
        min-height: 440px; }
      .owl-stage {
        padding: 50px 0; }
      &-item {
        .box-img {
          transition: all .3s ease-in-out;
          margin: 0 auto;
          max-width: 400px; } } } }

  &_news {
    padding-bottom: 80px; } }



